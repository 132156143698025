import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type PlentyCart } from '#types/cart'

type CartStore = {
	cart: PlentyCart | null
	count: () => number
	update: (newCart: PlentyCart) => void
}

export const useCartStore = create<CartStore>()(
	devtools((set, get) => ({
		cart: null,
		count: () => {
			const { cart } = get()
			if (cart) {
				return cart.totalLineItemQuantity || 0
			}

			return 0
		},
		update: (newCart: PlentyCart) => {
			set(() => ({ cart: newCart }), false, 'UPDATE_CART')
		},
	})),
)
